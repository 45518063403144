import React, { useContext } from 'react'
import { Title } from '../Map/MapElement'
import { OurProductWrap, Desc, ProductList, ProductCard, MoreBtn } from './OurProductElement'
import image1 from "../../../assets/graniteHome.jpg"
import image2 from "../../../assets/graniteImages/Ice-Blue.jpg"
import { useNavigate } from "react-router-dom";
import { Card } from 'react-bootstrap'
import { graniteData } from '../../Granite/GraniteData'
import { StoneContext } from '../../../Context'

function OurProduct() {
  const {setStone} = useContext(StoneContext);
  const navigate = useNavigate()
  const data = graniteData;
  return (
    <OurProductWrap id='products'>
      <Title style={{color: '#fff'}}>Our Products</Title>
      <Desc>
        We offer a diverse range of colors and patterns that are durable and visually stunning. Enables you to select the ideal options for your design requirements.
      </Desc>

      <ProductList>
        {
          data.slice(0, 8).map((item, key) => (
            <ProductCard key={key} onClick={() => {navigate('/product/' + item.id); setStone('granite')}} >
              <img src={item.image} alt="alt" />
              <h3>{item.Name}</h3>
            </ProductCard>
          ))
        }
      </ProductList>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <MoreBtn onClick={() => navigate('/product')}>View More</MoreBtn>
      </div>
    </OurProductWrap>
  )
}

export default OurProduct