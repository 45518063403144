import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './Pages/Home';
import ProductListPage from './Pages/ProductListPage';
import { ColorContext, StoneContext } from './Context';
import ProductPage from './Pages/ProductPage';
import AboutUsPage from './Pages/AboutUsPage';
import Terms from './Pages/Terms';
import PrivacyPolicy from './Pages/PrivacyPolicy';

function App() {
  const [color, setColor] = useState('');
  const [stone, setStone] = useState('granite')


  return (
    <ColorContext.Provider value={{ color, setColor }}  >
      <StoneContext.Provider value={{stone, setStone}} >
      <Router>
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/aboutUs' element={<AboutUsPage />} />
          <Route path='/product' element={<ProductListPage />} />
          <Route path='/product/:id' element={<ProductPage />} />
          <Route path='/terms&condition' element={<Terms />} />
          <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
        </Routes>
      </Router>
      </StoneContext.Provider>
    </ColorContext.Provider>

  );
}

export default App;
