import React from 'react'
import { FooterWrap, LogoSection, LinkSection, SocialIcon, WritesDesk, Rights, Terms, ContactCard, PagewLink as PageLink, PagewLinkProduct as PageHashLink, MobileView, MobileEnd } from './FooterElement'
import logo from '../../assets/logoElement.png';
import { BsFacebook, BsLinkedin } from 'react-icons/bs'
import { AiFillInstagram, AiFillTwitterCircle, AiFillYoutube } from 'react-icons/ai'
import fbIcon from '../../assets/fbIcon.png'
import instaIcon from '../../assets/instaIcon.png'
import twitterIcon from '../../assets/twitterIcon.png'
import { Link } from 'react-router-dom';



const Footer = () => {
    return (
        <>
            <div className='desktopView'>
                <FooterWrap>
                    <LogoSection>
                        <img src={logo} alt="Logo" />
                        <h1>MIRACLE STONEX</h1>
                    </LogoSection>
                    <LinkSection>
                        <h2 style={{ color: "red", margin: '0' }} >Links</h2>
                        {/* <PageLink to='/' >Home</PageLink> */}
                        <PageLink to='/aboutUs' >About</PageLink>
                        <PageHashLink to='/#products' >Products</PageHashLink>
                        <PageLink to='/getInTouch' >Get In Touch</PageLink>
                    </LinkSection>
                    <SocialIcon>
                        <a href="https://www.facebook.com/miracle.stonex.official" target="_blank"> <img src={fbIcon} style={{ width: '30px' }} /> </a>
                        <a href="https://www.instagram.com/miracle_stonex_official/" target="_blank"><img src={instaIcon} style={{ width: '30px' }} /> </a>
                        <a href="https://twitter.com/Miracle_StoneX" target="_blank"><img src={twitterIcon} style={{ width: '30px' }} /> </a>
                    </SocialIcon>
                    <ContactCard>
                        <h2 >Contact</h2>
                        <p>SY.NO. 702/2 & 711/2B, Thirumala Kota Road,
                            Near Kamandoddi, Konerpalli Post,
                            Pillaikothur Village, Krishnagiri District - 6351177<br />
                            Tamil Nadu, India<br />
                            Mobile No: +91 99866 62488
                        </p>
                    </ContactCard>

                </FooterWrap>
                <WritesDesk >
                    <Rights>Miracle Stonex © 2023 All Right Reserved.</Rights>
                    <Terms>
                        <Link style={{textDecoration: 'none', color: '#000'}} to='/terms&condition'><p>Terms of Use</p></Link>
                        <Link style={{textDecoration: 'none', color: '#000'}} to='privacyPolicy'><p>Privacy Policy</p></Link>
                    </Terms>
                </WritesDesk>
            </div>
            <MobileView>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <LogoSection>
                        <img src={logo} alt="Logo" />
                    </LogoSection>
                    <SocialIcon>
                        <a href="https://www.facebook.com/miracle.stonex.official" target="_blank"><BsFacebook style={{ color: '#3C5A9A' }} /></a>
                        <a href="https://www.instagram.com/miracle_stonex_official/" target="_blank"><AiFillInstagram style={{ color: '#A2359D' }} /></a>
                        <a href="https://twitter.com/Miracle_StoneX" target="_blank"><AiFillTwitterCircle style={{ color: '#1DA1F2' }} /></a>
                    </SocialIcon>
                </div>
                <LinkSection>
                    <h2 style={{ color: "red", margin: '0' }} >Links</h2>
                    {/* <PageLink to='/' >Home</PageLink> */}
                    <PageLink to='/aboutUs' >About</PageLink>
                    <PageHashLink to='/#products' >Products</PageHashLink>
                    <PageHashLink to='/#getInTouch' >Get In Touch</PageHashLink>
                </LinkSection>
                <ContactCard>
                    <h2 >Contact</h2>
                    <p>SY.NO. 702/2 & 711/2B, Thirumala Kota Road,
                        Near Kamandoddi, Konerpalli Post,
                        Pillaikothur Village, Krishnagiri District - 6351177<br />
                        Tamil Nadu, India<br />
                        Mobile No: +91 99866 62488
                    </p>
                </ContactCard>
                <MobileEnd>
                    <h6>Miracle Stonex © 2023 All Right Reserved.</h6>
                    <h5>
                        <Link style={{textDecoration: 'none', color: '#000'}} to='/terms&condition'><p>Terms of Use</p></Link>
                        <Link style={{textDecoration: 'none', color: '#000'}} to='privacyPolicy'><p>Privacy Policy</p></Link>
                    </h5>
                </MobileEnd>
            </MobileView>
        </>
    )
}

export default Footer