import React, { useState, useEffect } from 'react';
import { Wrap, Landing } from './AboutUsElement'
import Carousel from 'react-bootstrap/Carousel';
import Image1 from '../../assets/graniteImages/AlaskaGold.jpg'
import Image2 from '../../assets/graniteImages/Astoria.jpg'
import Image3 from '../../assets/graniteImages/BlackGalaxy.jpg'
import './AboutUs.css'
import { AboutWrap, Desc, Title } from './AboutUsElement'

const AboutUs = () => {

  

  //
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((activeIndex + 1) % 3);
    }, 3000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  const images = [Image1, Image2, Image3];
  const contents = [
    {
      title: "Quality of Products",
      description: "If the company offers high-quality granite products that are durable and visually appealing, it can set them apart from other companies."
    },
    {
      title: "Customisation Options",
      description: "Custom sizes, shapes, and edge treatments can make their products more attractive to customers who want unique and personalised solutions."
    },
    {
      title: "Competitive Pricing",
      description: "A company that offers competitive pricing can attract customers who are looking for quality products at an affordable price."
    },
  ];

  return (
    <AboutWrap>

      <div className="banner">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index}`}
            className={`banner-image ${activeIndex === index ? "active" : ""
              }`}
          />
        ))}
        {contents.map((content, index) => (
          <div
            key={index}
            className={`banner-content ${activeIndex === index ? "active" : ""
              }`}
          >
            <h1>{content.title}</h1>
            <p>{content.description}</p>
          </div>
        ))}
      </div>
      <div className="aboutContent">
        <Title>About</Title>
        <Desc>
          The Miracle StoneX is a premium provider of granite products and services. We have processed Indian, Italian marbles, gangsaw granite slabs and established a
          reputation for quality and have grown to be a reliable source for clients. Our business is focused on providing superior customer service, offering a wide
          range of high-quality granite products, from counter tops and flooring to tiles and slabs.<br></br><br></br>
          We extract, process, and finish our granite using state-of-the-art machinery, guaranteeing that every piece is of the greatest calibre. Our team of experts
          has the experience to offer specialized solutions and ensure that every project is finished to the satisfaction of the client.<br></br><br></br>
          At Miracle StoneX , we prioritize giving our clients a stress-free experience and streamlining their construction , We collaborate closely
          with our clients and offer the best goods and services.

        </Desc>
      </div>
    </AboutWrap>
  );

}

export default AboutUs