import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

export const BannerWrap = styled.div`
    height: calc(100vh - 20px);
    position: relative;
`;
export const Video = styled.video`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;
export const Content = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #00000060;

`;
export const Title = styled.h1`
    font-weight: 500;
    font-size: 48px;
    text-align: center;
    @media screen and (max-width: 750px){
        font-size: 32px;
    }
    
`;
export const Desc = styled.div`
   font-size: 1.5vw;
  
`;
export const Button = styled(HashLink)`
   height: 40px;
   padding: 0 1%;
   border: #fff 2px solid;
   border-radius: 15px;
   background: transparent;
   color: #fff;
   margin-top: 1%;
   transition: .3s;
   text-decoration:  none;
   display: flex;
   justify-content: center;
   align-items: center;

   


    &:hover{
        background-color: var(--primary-color);
        border: var(--primary-color) ;
        color: #fff;
    }
`;