import React, { useState, useEffect } from 'react'
import { SectionWrap, Left, Title, Desc, D1, D2, D3, Righ, Element } from './SecondSectionElement'
import './SecondSection.css'
import diamond from '../../../assets/diamond.svg'




const SecondSection = () => {


    const content = [
        {
            id: 1,
            text: "Miracle Stonex stands out with quality products, expert services, and personalized attention, using advanced technology to ensure premium quality and durability."
        },
        {
            id: 2,
            text: "Our dedicated team of skilled workers ensures customer satisfaction with excellent  service and project completion."
        },
        {
            id: 3,
            text: "Our company offers various colors, patterns, and textures, with customized cutting services to fit customers' design needs."
        }
    ];


    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((activeIndex + 1) % 3);
        }, 2000);
        return () => clearInterval(interval);
    }, [activeIndex]);

    return (
        <SectionWrap id='secondSection' >
            <Title>Our Expertise</Title>
            <Desc>
                <div className="container">
                    {content.map((item, index) => (
                        <div style={{display: 'flex', }}
                            key={item.id}
                            className={`content ${index === activeIndex ? "active" : ""}`}
                        >
                            <img src={diamond} style={{ marginRight: '20px'}} /> <p>{item.text}</p>
                        </div>
                    ))}
                </div>
            </Desc>
        </SectionWrap>
    )
}

export default SecondSection