import styled from "styled-components";

export const SectionWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8%;
    padding-top: 20px;
    padding-bottom: 2%;

    
    

    @media screen and (max-width: 750px){
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 5%; 
        img{
            display: none;
        }
    }

`;
export const Title = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    color: var(--primary-color);
    
`;

export const Desc = styled.div`
    font-weight: 400;
    line-height: 24px;
    font-size: 18px;
    height: 100%;
    margin-top: 3%;
    @media screen and (max-width: 750px){
        font-size: 16px;
    }
`;


export const Righ = styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;

     @media screen and (max-width: 750px){
        display: flex;
        justify-content: center;
        width: 100%;
    }

`;

export const Element = styled.img`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
`;
