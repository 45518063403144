import styled from "styled-components";

export const ProductWrap = styled.div`
    padding: 0 3%;
    margin-top: 140px;
`;

export const IndividualProduct = styled.div`
    width: 100%;
    display: flex;
    @media screen and (max-width: 750px){
        flex-direction: column-reverse;
    }
`;

export const Left = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 750px){
        width: 100%;
    }

    h1{
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 48px;
        color: var(--secondary-color);
    }

    p{
        font-family: 'Red Hat Display';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
    }
`;

export const Right = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;

    img{
        height: 234px;
        border-radius: 10px;
    }
    @media screen and (max-width: 750px){
        width: 100%;
        height: max-content;
        justify-content: center;

        img{
        width: 94%;
        border-radius: 10px;
    }
    }
`;

export const More = styled.div`
    margin-top: 40px;
`;

export const Title = styled.div`
    font-weight: 500;
    font-size: 36px;
    color: var(--primary-color);
`;

export const CardList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
`;

