import React, { useState, useContext, useEffect } from 'react'
import { SectionWrap, Filter, FilterMobile, FilterDesktop, ProductWrap, Stone, Color, Icon } from './ProductListElement'
import ProductCard from '../../ProductCard/ProductCard'
import { graniteData } from '../GraniteData'
import { marbleData } from '../MarbleData'
import { BsFillDiamondFill } from 'react-icons/bs'
import { ColorContext, StoneContext } from '../../../Context'
import { useLocation } from 'react-router-dom'
import { FiFilter } from 'react-icons/fi'

const ProductList = ({ page }) => {
  const { color, setColor } = useContext(ColorContext)
  const { stone, setStone } = useContext(StoneContext)
  const [filterActive, setFilterActive] = useState(false)


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [color]);

  let data = [];
  if (stone == 'granite') {
    data = graniteData;
  } else {
    data = marbleData;
  }



  return (
    <SectionWrap>
      <Filter>
        <FilterMobile filterActive={filterActive} onClick={() => setFilterActive(!filterActive)} ><FiFilter />Filter</FilterMobile>
        <FilterDesktop ><FiFilter />Filter</FilterDesktop>
        <Stone filterActive={filterActive}>
          <h2>Stone</h2>
          <h3 style={stone == 'granite' ? { marginRight: '10px', color: 'var(--secondary-color)' } : { marginRight: '10px' }} onClick={() => { setStone('granite'); setFilterActive(false) }}  ><Icon />Granite</h3>
          <h3 style={stone == 'marble' ? { marginRight: '10px', color: 'var(--secondary-color)' } : { marginRight: '10px' }} onClick={() => { setStone('marble'); setColor(''); setFilterActive(false) }} ><Icon />Marble</h3>
        </Stone>
        <Color filterActive={filterActive}>
          <h2>Color</h2>
          <div>

            <h3 style={color == '' ? { color: 'var(--secondary-color)' } : {}} onClick={() => { setColor(''); setFilterActive(false) }} ><Icon />All</h3>
            <h3 style={color == 'White' ? { color: 'var(--secondary-color)' } : {}} onClick={() => { setColor('White'); setFilterActive(false) }} ><Icon />White</h3>
            <h3 style={color == 'Black' ? { color: 'var(--secondary-color)' } : {}} onClick={() => { setColor('Black'); setFilterActive(false) }} ><Icon />Black</h3>
            <h3 style={color == 'Cream' ? { color: 'var(--secondary-color)' } : {}} onClick={() => { setColor('Cream'); setFilterActive(false) }} ><Icon />Cream</h3>
            <h3 style={color == 'gold' ? { color: 'var(--secondary-color)' } : {}} onClick={() => { setColor('gold'); setFilterActive(false) }} ><Icon />Gold</h3>
            <h3 style={color == 'Blue' ? { color: 'var(--secondary-color)' } : {}} onClick={() => { setColor('Blue'); setFilterActive(false) }}><Icon />Blue</h3>
            <h3 style={color == 'Green' ? { color: 'var(--secondary-color)' } : {}} onClick={() => { setColor('Green'); setFilterActive(false) }}><Icon />Green</h3>
            <h3 style={color == 'Brown' ? { color: 'var(--secondary-color)' } : {}} onClick={() => { setColor('Brown'); setFilterActive(false) }}><Icon />Brown</h3>
            <h3 style={color == 'Red' ? { color: 'var(--secondary-color)' } : {}} onClick={() => { setColor('Red'); setFilterActive(false) }}><Icon />Red</h3>
            <h3 style={color == 'Grey' ? { color: 'var(--secondary-color)' } : {}} onClick={() => { setColor('Grey'); setFilterActive(false) }}><Icon />Grey</h3>
            <h3 style={color == 'Silver' ? { color: 'var(--secondary-color)' } : {}} onClick={() => { setColor('Silver'); setFilterActive(false) }}><Icon />Silver</h3>
            <h3 style={color == 'Pink' ? { color: 'var(--secondary-color)' } : {}} onClick={() => { setColor('Pink'); setFilterActive(false) }}><Icon />Pink</h3>
          </div>
        </Color>
      </Filter>
      <ProductWrap>
        {
          data.map((item, key) => {
            if (color == '')
              return <ProductCard key={key} name={item.Name} desc={item.desc} image={item.image} id={item.id} />
            if (color == item.color)
              return <ProductCard key={key} name={item.Name} desc={item.desc} image={item.image} id={item.id} />
          })
        }

      </ProductWrap>
    </SectionWrap>
  )
}

export default ProductList