import styled from "styled-components";
import img from '../../assets/homepageBg.jpg'

export const Wrap = styled.div`
    padding-top: 5%;
    padding-bottom: 5%;
    background-image: url(${img});
    display: flex;
    align-items: center;
    @media screen and (max-width: 750px){
      flex-direction: column;
      align-items: flex-start;
    }
`;

export const Left = styled.div`
    width: 60%;
    padding-left: 10%;

    h1{
        font-weight: 500;
        font-size: 64px;
        background: #fff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    @media screen and (max-width: 750px){
        
        width: 90%;
        h1{
            font-size: 28px;
        }
    }
`;

export const Right = styled.div`
    width: 40%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 750px){
      width: 100%;
      display: flex;
      justify-content: center;
}    
`;

export const FormWrap = styled.form`
    background: #fff;
    width: 70%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;

    p{
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 0;
    }
    input{
        margin-top: 7%;
        height: 30px;
        border: none;
        border-bottom: 1px #000 solid;
    }
    input:focus{
        outline: none;
    }
    textarea{
        margin-top: 5%;
        margin-top: 60px;
        height: 40px;
        border: none;
        font-size: 20px;
        border-bottom: 1px #000 solid;
    }
    textarea:focus{
        outline: none;
    }
    @media screen and (max-width: 750px){
        width: 80%;
    }
`;

export const TopSection = styled.div`
    display: flex;
    align-items: center;
    h2{
        width: 80%;
        margin: 0;
    }
    img{
        height: 50px;
    }
    
`;

export const Btn = styled.div`

    input[type=submit]{
        width: 30%;
        border: none;
        background: #FF9634;
        border-radius: 5px;
        color: #fff;
    }
    input[type=submit]:hover{
        cursor: ${({sending}) => (sending ? 'not-allowed' : 'pointer')};
    }
`;