import React, { } from 'react'
import { MapWrap, Title, Desc, Hr, IndiaMap, StateMap, Left, KImg, JImg, TImg, Right, KContent, KContent02, TContent, JContent, Tamilnadu, Karnataka, Jaipur, Line } from './MapElement'
import tamilnadu from '../../../assets/StateMap/tamilnadu.png'
import karnataka from '../../../assets/StateMap/karnataka.png'
import jaipur from '../../../assets/StateMap/jaipur.png'
import callIcon from '../../../assets/callIcon.svg'

const Map = () => {
    return (
        <MapWrap>
            <Title>
                Our Presence
            </Title>
            <Desc>
                <span style={{color: 'var(--secondary-color)'}}>Miracle Stonex</span> is a  leading provider of premium granite products and services throughout India with a priority on providing excellence and client satisfaction.
            </Desc>
            {/* <IndiaMap >
                <img src={map} alt="India Map" useMap='#markMap' />
                <map name='markMap'>
                    <area shape="circle" coords="34,44,270,350" alt="Computer" href="computer.htm" />
                    <area shape="rect" coords="290,172,333,250" alt="Phone" href="phone.htm" />
                    <area shape="circle" coords="337,300,44" alt="Cup of coffee" href="coffee.htm" />
                </map>
            </IndiaMap> */}


            <StateMap>
                <Karnataka>
                    <Left>
                        <KImg src={karnataka} alt="" />
                    </Left>
                    <Right>
                        <KContent>
                            <h5>MIRACLE STONEX PRIVATE LIMITED</h5>
                            <p>197/4 , Thamasandra Village Kasaba Hobli Kanakpura Taluk, Ramanagara Dist, 562117
                                Karnataka, India<br></br>
                                <span><img src={callIcon} /> +91 99866 62488</span></p>
                        </KContent>
                        <Line></Line>
                        {/* <Hr width="100%" size='30px'></Hr> */}
                        <KContent02>
                            <h5>MIRACLE STONEX PRIVATE LIMITED</h5>
                            <p>SY NO. 16/2 Begihalli Village Jigani Hobli, Anekal Taluk 560083
                                Bangalore, India<br></br><br></br>
                                <span><img src={callIcon} />+91 88248 84857</span></p>
                        </KContent02>
                    </Right>
                </Karnataka>

                <Tamilnadu>
                    <Left>
                        <TImg src={tamilnadu} alt="" />
                    </Left>
                    <Right>
                        <TContent>
                            <h5>MIRACLE STONEX PRIVATE LIMITED</h5>
                            <p>SY. NO. 702/2 & 711/2B, Thirumala Kota Road, Near Kamandoddi, Konerpalli Post, Pillaikothur Village, Krishnagiri District - 635117
                                Tamil Nadu, India<br></br>
                                <span><img src={callIcon} />+91 99866 62488</span></p>
                        </TContent>
                    </Right>
                </Tamilnadu>

                <Jaipur>
                    <Left>
                        <JImg src={jaipur} alt="" />
                    </Left>
                    <Right>
                        <JContent>
                            <h5>MIRACLE STONEX PRIVATE LIMITED</h5>
                            <p>K.No. 181-185, Village Akhepura, Near Kurkure Factory, Mokhampura Choraha Jaipur-Kishangarh-Ajmer
                                National Highway, (Raj.) Jaipur, India<br></br>
                                <span><img src={callIcon} />+91 98451 49421</span></p>
                        </JContent>
                    </Right>
                </Jaipur>
            </StateMap>


        </MapWrap>
    )
}

export default Map