import React, {useState} from 'react'
import Footer from '../Components/Footer/Footer'
import Banner from '../Components/Home/LandingBanner/Banner'
import Map from '../Components/Home/Map/Map'
import OurClients from '../Components/Home/OurClients/OurClients'
import OurProduct from '../Components/Home/OurProduct/OurProduct'
import SecondSection from '../Components/Home/SecondSection/SecondSection'
import Testimonials from '../Components/Home/Testimonials/Testimonials'
import NavBar from '../Components/NavBar/NavBar'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import GetInTouch from '../Components/GetInTouch/GetInTouch'

const Home = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 40) {
      setColorchange(true);
      
    }
    else {
      setColorchange(false);
    }
  };
  window.addEventListener('scroll', changeNavbarColor);


  return (
    <>
      <NavBar colorChange={colorChange} />
      <Banner />
      <SecondSection />
      <OurProduct />
      <Map />
      {/* <Testimonials />
      <OurClients /> */}
      <GetInTouch />
      <Footer />
    </>
  )
}

export default Home