import React,{useState} from 'react'
import NavBar from '../Components/NavBar/NavBar'
import Footer from '../Components/Footer/Footer'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AboutUs from '../Components/AboutUs/About';

const AboutUsPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 40) {
      setColorchange(true);

    }
    else {
      setColorchange(false);
    }
  };
  window.addEventListener('scroll', changeNavbarColor);


  return (
    <>
      <NavBar colorChange={colorChange} />
      <AboutUs />
      <Footer />
    </>
  )
}

export default AboutUsPage