import styled from "styled-components";
import { Link } from 'react-router-dom'
import { HashLink } from "react-router-hash-link";


export const NavWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95px;
    /* background: ${({ dark }) => (dark ? '' : '')}; */
    background: ${({ colorChange }) => (colorChange ? '#fff' : 'transparent')} ;
    font-weight: 500;
    font-size: 18px;   
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    transition: .4s;
    box-shadow: ${({ colorChange }) => (colorChange ? '0px 0.1px 10px' : '')};

    @media screen and (max-width: 750px){
        display: none;
    }

`;
export const NavList = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
`;

export const ItemHome = styled(Link)`
    margin: 0px 2%;
    width: max-content;
    color: ${({ pathname }) => (pathname == '/' ? 'var(--primary-color)' : 'var(--secondary-color)')} ;
    text-decoration:  none; 
    &:hover{
        color: ${({ pathname }) => (pathname == '/' ? 'var(--secondary-color)' : 'var(--primary-color)')};
    }   
`;
export const ItemAbout = styled(Link)`
    margin: 0px 2%;
    color: ${({ pathname }) => (pathname == '/aboutUs' ? 'var(--primary-color)' : 'var(--secondary-color)')} ;
    text-decoration:  none; 
    &:hover{
        color: var(--primary-color);
    }   
`;


export const ItemProduct = styled(HashLink)`
    margin: 0px 2%;
    color: var(--secondary-color);
    text-decoration:  none;
    cursor: pointer;
    &:hover{
        color: var(--primary-color);
    }
`;

export const LogoWrap = styled(Link)`
    margin: 0px 6%;
`;
export const Logo = styled.img`
    height: 95px;
`;

export const Btn = styled.button`
    margin: 0px 2%;
    background: transparent;
    width: max-content;
    height: 30px;
    padding-top: 2px;
    color: var(--secondary-color);
    border-color: var(--secondary-color);
    border: 1px;
    border-style: solid;

    &:hover{
        cursor: pointer;
        color: var(--primary-color);
    }
`;

export const MobileView = styled.div`
    display: none;
    padding: 10px 20px;
    font-size: 30px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    margin-bottom: -100px;
    height: 100%;
    align-items: center;
    box-shadow: ${({ colorChange }) => (colorChange ? '0px 0.1px 10px' : '')};
    background: ${({ colorChange }) => (colorChange ? '#fff' : 'transparent')} ;

    @media screen and (max-width: 750px){
        display: flex;
    }

`;
export const LogoWrapMobile = styled(Link)`
    padding: 0px 6%;
    width: 70%;
`;

export const LogoMobile = styled.img`
    height: 60px;
`;

export const Hamburger = styled.div`
    width: 30%;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 40px;
    color: #000;
`;
