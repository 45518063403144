import styled from "styled-components";
import { keyframes } from 'styled-components'


export const MapWrap = styled.div`
    padding: 8%;
    padding-top: 3%;
    padding-bottom: 5%;
    /* border-bottom: solid 1px; */
    @media screen and (max-width: 750px){
      padding: 3%;
      /* display: none; */

    }
    
`;

export const Title = styled.h1`
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    color: var(--primary-color);
`;

export const Desc = styled.div`
    font-weight: 400;
    line-height: 24px;
    font-size: 18px;
    font-style: normal;
    width: 65%;
    @media screen and (max-width: 750px){
       width: 100%;
       font-size: 15px;
    }

`;

export const IndiaMap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
    position: relative;
    margin-top: 5%;
    img {
        width: 50%;
    }
`;


export const StateMap = styled.div`
    margin-top: 5%;
`;
export const Left = styled.div`
     width: 30%;
     display: flex;
     align-items: center;
     justify-content: center;
     @media screen and (max-width: 1100px){
        width: 100%;
    }
`;



const tImg = keyframes`
        0%{
            opacity: 0.5;
        }
        5%{
            opacity: 1;
            
        }
        20%{
            opacity: 1;
        }
        33%{
            opacity: 0;
        }
        66%{
            opacity: 0;
        }
        100%{
            opacity: 0;
        }
`;
const kImg = keyframes`
    0%{
        opacity: 0;
    }
    20%{
        opacity: 0;
        
    }
    33%{
        opacity: 1;
    }
    60%{
        opacity: 1;
    }
    67%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
`;
const jImg = keyframes`
       0%{
            opacity: 0;
        }
        33%{
            opacity: 0;
        }
        60%{
            opacity: 0;
        }
        67%{
            opacity: 1;
        }
        80%{
            opacity: 1;
        }
        100%{
            opacity: 0.5;
        }
`;


export const TImg = styled.img`
   animation: ${tImg} 15s ease-in-out infinite;
   @media screen and (max-width: 1100px){
        animation: none;
    }
`;
export const KImg = styled.img`
   animation: ${kImg} 15s ease-in-out infinite;
   @media screen and (max-width: 1100px){
        animation: none;
    }
`;
export const JImg = styled.img`
   animation: ${jImg} 15s ease-in-out infinite;
   @media screen and (max-width: 1100px){
        animation: none;
    }
`;
export const Right = styled.div`
    width: 70%;
    display: flex;
     align-items: center;
     justify-content: end;
     padding-right: 20%;
     
     h5{
        font-size: 16px;
        font-weight: 600;
    }
    p{
        width: 250px;
    }

    h1{
        background: rgb(255,136,27);
        background: linear-gradient(0deg, rgba(255,136,27,1) 26%, rgba(255,255,255,1) 76%);
        width: 5px;
        height: 100%;
        border-radius: 20px;
    }
    span{
        color: var(--secondary-color);
    }
    @media screen and (max-width: 1100px){
       flex-direction:column ;
       margin-top: 5%;
       padding: 0 3%;
    }
`;




const tCont = keyframes`
        0%{
            opacity: 1;
        }
        15%{
            transform: translate(0px 0px);
        }
        20%{
            opacity: 1;
            transform: translate(-240px, 0px);
        }
        33%{
            opacity: 0;
        }
        66%{
            opacity: 0;
        }
        100%{
            opacity: 0;
        }
`;
const kCont = keyframes`
    0%{
        opacity: 0;
    }
    20%{
        opacity: 0;
        
    }
    30%{
        
    }
    33%{
        opacity: 1;
    }
    40%{
        transform: translate(0px, 0px);
    }
    60%{
        opacity: 1;
        transform: translate(265px, 0px);
    }
    67%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
`;

const kCont02 = keyframes`
   0%{
        opacity: 0;
    }
    20%{
        opacity: 0;
        
    }
    33%{
        opacity: 1;
    }
    50%{
        opacity: 1;
    }
    60%{
        opacity: 0;
    }
    67%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
`;

const jCont = keyframes`
       0%{
            opacity: 0;
        }
        33%{
            opacity: 0;
        }
        60%{
            opacity: 0;
        }
        67%{
            opacity: 1;
        }
        80%{
            opacity: 1;
        }
        100%{
            opacity: 1;
        }
`;
const line = keyframes`
   0%{
        opacity: 0;
    }
    20%{
        opacity: 0;
        
    }
    33%{
        opacity: 1;
        background: rgb(255,136,27);
    background: linear-gradient(0deg, rgba(255,136,27,1) 26%, rgba(255,255,255,1) 76%);
    }
    40%{
        opacity: 1;
       
    }
    50%{
        opacity: 1;
       
    }
    60%{
        opacity: 0;
        background: rgb(255,136,27);
        background: linear-gradient(0deg, rgba(255,136,27,1) 45%, rgba(255,255,255,1) 68%);
    }
    67%{
        opacity: 0;
    }
    100%{
        opacity: 0;
    }
`;
export const TContent = styled.div`
    animation: ${tCont} 15s ease-in-out infinite;
    transition: transform .3s ease-in-out;
    @media screen and (max-width: 1100px){
        animation: none;
    }
    
`;
export const KContent = styled.div`
    animation: ${kCont} 15s ease-in-out infinite;
    transition: transform .3s ease-in-out;
    @media screen and (max-width: 1100px){
        animation: none;
    }
    
    
`;
export const KContent02 = styled.div`
    animation: ${kCont02} 15s ease-in-out infinite;
    transform: translate(100%, 0px);
    transition: transform .3s ease-in-out;
    @media screen and (max-width: 1100px){
        animation: none;
        transform: none;
        margin-top: 5%;
    }
`;
export const Line = styled.div`
    animation: ${line} 15s ease-in-out infinite;
    transform: translate(5000%, 0px);   
    width: 5px;
    height: 100%;
    border-radius: 20px;
    @media screen and (max-width: 1100px){
        animation: none;
    }
`;
export const Hr = styled.hr`
    display: none;
    background: var(--secondary-color);
    color: var(--secondary-color);
    border: none;
    height: 8px;

    @media screen and (max-width: 1100px){
        display: flex;
    }
    
`;
export const JContent = styled.div`
    animation: ${jCont} 15s ease-in-out infinite;
    transform: translate(0px, 0px);
    transition: transform .3s ease-in-out;
    @media screen and (max-width: 1100px){
        animation: none;
    }
`;


export const Tamilnadu = styled.div`
    display: flex;
    margin-top: -177px;
    @media screen and (max-width: 1100px){
        flex-direction: column;
        margin-top: 20px;
     }
`;
export const Karnataka = styled.div`
    display: flex;
     @media screen and (max-width: 1100px){
        flex-direction: column;
        margin-top: 20px;
     }
`;
export const Jaipur = styled.div`
    display: flex;
    margin-top: -200px;
    @media screen and (max-width: 1100px){
        flex-direction: column;
        margin-top: 20px;
     }
`;