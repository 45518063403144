import styled from "styled-components";
import img from '../../../assets/homepageBg.jpg'


export const OurProductWrap = styled.div`
    padding: 8%;
    padding-top: 3%;
    padding-bottom: 3%;
    /* border-bottom: solid 1px; */
    background-image: url(${img});

    @media screen and (max-width: 650px){
        padding-top: 10%;
        padding: 5%;
    }
`;
export const Product = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    color: #fff;
    h1{
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: 0;
        opacity: 100%;
    }
    h1:hover{
        opacity: 0;
        color: #000;
    }

    button{
        background: #FF9634;
        border-radius: 10px;    
        height: 40px;
        width: 100px;
        border: none;
        color: #fff;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
    }

    button:hover{
        background: #FBAE17;
        cursor: pointer;
    }
    @media screen and (max-width: 750px){
       flex-direction: column;
    }

`;
export const Desc = styled.div`
    color: #fff;
    font-weight: 400;
    line-height: 24px;
    font-size: 18px;
    font-style: normal;
    width: 65%;
    @media screen and (max-width: 750px){
       width: 100%;
       font-size: 15px;
    }
`;
export const Granite = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 50%;
    height: max-content;
    margin-right: 10px;

    img{
        width: 100%;
        height: 340px;
        object-fit: cover;
        border-radius: 10px;
    }

    &:hover{
       h1{
        opacity: 0;
       }
    }
    @media screen and (max-width: 750px){
        width: 100%;
    }

`;

export const Marble = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 50%;
    height: max-content;
    margin-right: 10px;

    img{
        width: 100%;
        border-radius: 10px;
        object-fit: cover;
        height: 340px;
    }

    h1{
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: 0;
    }
    &:hover{
       h1{
        opacity: 0;
       }
    }
    @media screen and (max-width: 750px){
        width: 100%;
        margin-top: 20px;
    }
`;

export const GContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s;
    border-radius: 10px;
    

    &:hover{
        opacity: 100%;
        background: rgba(0, 0, 0, 0.51);
        h1{
            opacity: 0;
        }
    }
`;

export const MContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s;
    border-radius: 10px;

    &:hover{
        opacity: 100%;
        background: rgba(0, 0, 0, 0.51);
    }
`;

export const ProductList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 3%;
    justify-content: center;
`;
export const ProductCard = styled.div`
    position: relative;
    height: max-content;
    max-width: 300px;
    min-width: 200px;
    flex: 1 1 15rem;
    @media screen and (max-width: 750px){
        min-width: 35%;
        max-width: 42%;
        flex: 1 1 10rem;
        margin: 5px;
    }
    
    img{
        width: 100%;
        height: 300px;
        border-radius: 10px;
        object-fit: cover;

        @media screen and (max-width: 750px){
        height: 200px;
    }
    }
    h3{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
        background:  rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        transition: .2s;
        font-size: 42px;
        font-weight: 500;
        &:hover{
        cursor: pointer;
        background:  rgba(0, 0, 0, 0.7);
        }
        @media screen and (max-width: 750px){
            font-size: 8vw;
        }
    }
    
`;
export const MoreBtn = styled.button`
    height: 40px;
    padding: 0px 20px;
    border: none;
    border-radius: 10px;
    background: var(--secondary-color);
    color: #fff;
    &:hover{
        background:  #FBAE17;
    }
`;