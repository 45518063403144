import React, { useState } from 'react'
import { CardWrap, Image, Title, Desc, Btn, Link } from './ProductCardElement'
import { useNavigate } from 'react-router-dom';



const ProductCard = ({ name, desc, image, id }) => {
  const navigate = useNavigate();
 
  
  return (
    <CardWrap>
      <Image style={{cursor: 'pointer'}} src={image} alt='product Img' onClick={() => navigate('/product/' + id)} />
      <Title style={{cursor: 'pointer'}} onClick={() => navigate('/product/' + id)} >{name}</Title>
      <Desc style={{cursor: 'pointer'}} onClick={() => navigate('/product/' + id)} >{desc.substr(0, 120) + '...'}</Desc>
      <Btn>
        <Link to='/#getInTouch' onClick={() => navigate('/GetInTouch')}>Enquiry</Link>
      </Btn>
    </CardWrap>
  )
}
export default ProductCard
