import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import Footer from '../Components/Footer/Footer';
import { graniteData } from '../Components/Granite/GraniteData';
import { marbleData } from '../Components/Granite/MarbleData';
import NavBar from '../Components/NavBar/NavBar';
import Product from '../Components/IndividualProductPage/Product';


const ProductPage = () => {
    const { pathname } = useLocation();

    const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () => {
        if (window.scrollY >= 40) {
            setColorchange(true);

        }
        else {
            setColorchange(false);
        }
    };
    window.addEventListener('scroll', changeNavbarColor);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);



    return (
        <>
            <NavBar colorChange={colorChange} />
            <Product />
            <Footer />
        </>
    )
}

export default ProductPage