import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { StoneContext } from '../../Context';
import { graniteData } from '../Granite/GraniteData';
import { marbleData } from '../Granite/MarbleData';
import { MoreBtn } from '../Home/OurProduct/OurProductElement';
import ProductCard from '../ProductCard/ProductCard';
import { ProductWrap, IndividualProduct, Left, Right, More, Title, CardList } from './ProductElement';
import { useNavigate } from 'react-router-dom';


const Product = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const [img, setImg] = useState('')
    const {stone} = useContext(StoneContext)


    const { pathname } = useLocation();

    let data = [];
    if(stone == 'granite'){
        data = graniteData
    }
    else{
        data = marbleData
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        data.map((item) => {
            if ("/product/" + item.id == pathname) {
                setName(item.Name);
                setDesc(item.desc);
                setImg(item.image);
            }
        })

    }, [pathname]);


    return (
        
            <ProductWrap>
                <IndividualProduct>
                    <Left>
                        <h1>{name}</h1>
                        <p>{desc}</p>
                    </Left>
                    <Right>
                        <img src={img} alt="" />
                    </Right>
                </IndividualProduct>
                <More>
                    <Title>More Product</Title>
                    <CardList>
                        {data.slice(0, 4).map((item, key) => (
                            <ProductCard key={key} name={item.Name} desc={item.desc} image={item.image} id={item.id}  />
                        ))}
                    </CardList>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px'}} ><MoreBtn onClick={() => navigate('/product')}>View More</MoreBtn></div>
                </More>
            </ProductWrap>
    )
}

export default Product