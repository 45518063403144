import styled from "styled-components";

export const AboutWrap = styled.div`
    @media screen and (max-width: 750px){
        h1{
            font-size: 32px;
        }
        p{
            width: 100%;
        }
    }
`;
export const Title = styled.div`
    font-weight: 500;
    font-size: 36px;
    color: var(--primary-color);
`;
 
export const Desc = styled.div`
    font-weight: 400;
    line-height: 24px;
    font-size: 18px;
    width: 60%;
    font-style: normal;
    /* margin-top: 3%; */

    @media screen and (max-width: 750px){
       font-size: 15px;
    }
`;