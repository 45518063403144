import React,{useState} from 'react'
import TopSection from '../Components/Granite/TopSection/GraniteMarble'
import ProductList from '../Components/Granite/ProductList/ProductList'
import NavBar from '../Components/NavBar/NavBar'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from '../Components/Footer/Footer';

const ProductListPage = () => {
  const { pathname } = useLocation();
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 40) {
      setColorchange(true);
      
    }
    else {
      setColorchange(false);
    }
  };
  window.addEventListener('scroll', changeNavbarColor);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <>
    <NavBar colorChange={colorChange} />
    <TopSection />
    <ProductList />
    <Footer />
    </>
  )
}

export default ProductListPage